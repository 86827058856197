@import "../../../scss/base/colorswatches.scss";

.views-profiles-edit {
  .onboarding {
    text-align: center;
    margin: 20px 0 40px 0;

    .instructions {
      margin: 0 auto;
      font-size: 16px;
      max-width: 600px;
      color: $gray-light-1;
    }
  }

  .purchases-link {
    position: absolute;
    top: 10px;
    right: 20px;
    color: $gray;

    &:hover {
      color: $primary-color;
    }
  }

  .edit-profile-form {
    margin-bottom: 40px;

    .form-section {
      margin: 20px 0;
      h2 {
        color: $gray;
      }
    }

    .errors {
      margin: 10px 0;
      color: $error-color;
    }
  }
}
