@import '../../../scss/base/colorswatches.scss';

.views-deals-purchase-confirmation {
  .message {
    text-align: center;
    .main {
      font-weight: bold;
      font-size: 32px;
    }

    .instructions {
      font-size: 16px;
      margin-top: 10px;
    }

    .disclaimer {
      color: $gray;
      margin-top: 10px;
    }
  }
  
  .more-deals-container {
    text-align: center;
    margin: 40px 0;
  }
}