@import '../../scss/base/colorswatches.scss';

.component-payments-setup {
  text-align: center;

  .successful-setup {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto 20px auto;
    width: 350px;
    color: $success-color;

    border: 1px solid $success-color;
    padding: 20px;
    border-radius: 4px;
    background: $success-color-light-3;

    i {
      font-size: 40px;
      margin-right: 15px;
    }

    .text {
      text-align: left;
    }
  }

  .edit-stripe-account-container {
    margin-top: 20px;
    text-align: center;
  }

  .stripe-legal {
    max-width: 600px;
    margin: 40px auto;
    font-size: 12px;
  }
}
