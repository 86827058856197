@import "../../scss/base/colorswatches.scss";

.component-deal-special-overlay {
  position: absolute;
  color: white;
  width: 330px;
  margin-bottom: 14px;

  text-align: center;

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 36px;
  }
  .deal-type-container {
    position: absolute;
    top: 5px;
    font-weight: bold;
    font-size: 16px;
    z-index: 2;
    color: white;
    display: flex;
    align-items: center;
    width: 100%;
    text-transform: capitalize;

    i {
      margin-right: 10px;
      font-size: 16px;
    }
    .fa-clock-o:before {
      font-size: 18px;
    }
  }
  .triggered {
    text-transform: none;
    top: 2px;
    .triggered-text {
      line-height: 19px;
    }
  }

  i {
    margin-right: 10px;
  }

  &.small {
    font-weight: bold;
    font-size: 13px;
  }

  @media screen and (max-width: 900px) {
    img {
      height: 30px;
    }
    .deal-type-container {
      top: 1px;
      align-items: center;
      font-size: 13px;
      padding: 5px 10px 0 10px;
    }
    .triggered {
      top: -2px;
      padding: 4px 10px 2px 10px;
      .triggered-text {
        line-height: 14px;
      }
    }
  }
}
