@import '../../../scss/base/colorswatches.scss';

.component-deals-tab {
  .deal-filters {
    display: flex;
    align-items: center;

    .search-bar-container {
      flex-grow: 1;
    }

    .component-deal-type-refinement-list {
      width: 360px;
    }
  }
  
  .component-results-with-empty-state.empty-state {
    text-align: center;
    padding: 125px 0;
    font-size: 24px;
    color: $gray-light-2;
  }

  .algolia-powered-by-container {
    margin: 30px 0;
  }

  .search-results .ais-Hits-list {
    display: grid !important;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 790px) {
    .deal-filters {
      display: block;

      .component-deal-type-refinement-list {
        width: 100%;
      }

      .search-bar-container {
        width: 100%;
      }
    }

    .search-results .ais-Hits-list {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 500px) {
    .search-results .ais-Hits-list {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}