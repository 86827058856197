.component-login-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1069;
  overflow: auto;

  .close-modal-container {
    margin-top: 40px;
    margin-bottom: 10px;

    .close-modal-icon-container {
      color: white;
      text-align: right;

      i {
        cursor: pointer;
      }
    }
  }
}
