@import "../../../scss/base/globals.scss";
@import "../../../scss/base/colorswatches.scss";

.views-deals-show-flash {
  .component-loading-placeholder {
    margin: 50px auto;
  }

  .archived {
    text-align: center;
    max-width: 600px;
    margin: 125px auto 400px auto;
    color: $gray-light-1;
    font-size: 24px;

    .archived-header {
      font-size: 40px;
    }
  }

  .deal-header {
    display: flex;
    margin-bottom: 40px;

    .business {
      width: 50%;
      display: flex;
      align-items: center;

      .business-logo {
        width: 125px;
      }

      .business-details {
        margin-left: 20px;

        .business-name {
          font-size: 24px;
          font-weight: 800;
        }

        .business-address {
          font-size: 16px;
        }
      }
    }

    a.organization {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row-reverse;
      color: black;

      .organization-details {
        margin-right: 20px;
      }

      .organization-logo {
        width: 125px;
      }
    }
  }

  .not-activated-container {
    margin: 80px 0;

    .description {
      text-align: center;
      margin: 20px 0 5px 0;
      color: $primary-color;
      font-size: 26px;
      font-weight: 800;
    }

    .terms {
      text-align: center;
      width: 100%;
      max-width: 500px;
      margin: 5px auto 30px auto;
      font-size: 16px;
    }

    .not-activated-message {
      text-align: center;
      color: $gray-light-1;
      font-size: 24px;
    }
  }

  .marquee {
    display: flex;
    margin-bottom: 80px;

    .image-area {
      width: 50%;
      position: relative;

      img.deal-picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .deal-overlays {
        position: absolute;
        left: 0;
        bottom: 0;

        .component-special-overlay,
        .component-deal-type-overlay {
          position: relative;
          margin-bottom: 10px;
          width: 240px;
          padding: 0 20px;
          text-align: left;
        }
      }
    }

    .writeup {
      margin-left: 20px;
      width: calc(50% - 20px);

      .description {
        color: $primary-color;
        font-size: 26px;
        font-weight: 800;
      }

      .flash-deal-details {
        .original-value {
          margin-right: 10px;
          color: $gray;
        }

        .price {
          font-size: 28px;
          color: $primary-color-light-1;
          display: flex;
          align-items: center;

          .component-deal-percentage-off {
            background: $primary-color-light-2;
            padding: 7px 12px;
            font-size: 14px;
            margin-left: 10px;
            font-weight: bold;
          }
        }

        .quantity-and-expiry {
          display: flex;
          font-size: 14px;

          .total-left {
            margin-right: 4px;
          }
          .expiry {
            margin-left: 4px;
          }
        }
      }

      .actions {
        margin: 10px 0;

        .component-redeem-deal-link {
          display: inline-block;
          border-radius: $border-radius;
          padding: 10px 45px;
          color: white;
          text-transform: uppercase;
          font-weight: 800;

          background: $primary-color;

          &.purchase {
            background: $fansaves-green;
          }
        }

        .no-longer-available {
          padding: 10px;
          border: 1px solid $error-color;
          color: $error-color;
          background: $error-color-light-3;
          font-weight: bold;
        }
      }

      .terms {
        font-size: 18px;
      }

      .flash-deal-instructions {
        margin-top: 50px;
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }

      .download-app-info {
        margin-top: 50px;
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: bold;

        .download-instructions {
          font-size: 14px;
        }

        .download-app {
          background: $fansaves-green;
          color: white;
          width: 260px;
          margin: 10px auto;
          font-size: 20px;
          text-transform: uppercase;
          padding: 5px 30px;
          font-weight: bold;
        }

        .app-links {
          a {
            margin: 5px;

            img {
              height: 40px;
            }
          }
        }
      }
    }
  }

  .sponsor-info {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 40px;

    .map {
      width: 50%;
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 300px;
      }
    }

    .sponsor-details {
      margin-left: 20px;
      width: calc(50% - 20px);

      h2 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px;
      }

      .address {
        margin: 10px 0;
      }

      .contact-details {
        margin: 20px 0;
        display: flex;

        a {
          font-size: 25px;
          margin-right: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .deal-header {
      display: block;
      text-align: center;

      .business {
        display: block;
        width: 100%;
        margin-bottom: 20px;

        .business-details {
          margin-left: 0px;
        }
      }

      a.organization {
        display: block;
        width: 100%;

        .organization-details {
          margin-right: 0px;
        }
      }
    }

    .marquee {
      display: block;

      .image-area {
        width: 100%;
      }

      .writeup {
        width: 100%;
        margin: 0;

        text-align: center;

        .component-redeem-deal-link {
          width: 100%;
        }

        .flash-deal-details {
          .price {
            justify-content: center;
          }

          .quantity-and-expiry {
            justify-content: center;
          }
        }
      }
    }

    .sponsor-info {
      display: block;

      .map {
        width: 100%;
      }

      .sponsor-details {
        width: 100%;
        margin: 0;

        text-align: center;

        .contact-details {
          justify-content: center;
        }
      }
    }
  }
}
