@import "../../scss/base/colorswatches.scss";

.component-follow-organization-modal {
  .main-content {
    .not-available {
      text-align: center;

      a {
        text-decoration: underline;
      }
    }

    .follow-message {
      text-align: center;
      color: $primary-blue;
    }

    .organizations {
      .organization {
        text-align: center;

        .logo-container {
          margin: 10px 0;
          img.logo {
            height: 100px;
          }
        }

        .name {
          margin-bottom: 20px;
          color: $primary-blue;
        }

        .component-follow-organization-link {
          margin-bottom: 20px;
        }
      }
    }
  }
}
