@import "../../scss/base/colorswatches.scss";

.component-unlock-exclusive-modal {
  .main-content {
    .unclock-exclusive-code-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .unclock-exclusive-code-form-header {
        margin-bottom: 20px;
        color: $primary-blue;
        .unclock-exclusive-code-form-header-icon {
          margin-right: 10px;
          color: $primary-green;
          font-size: 24px;
        }
      }

      .unclock-exclusive-code-form-input {
        border: 1px solid $primary-dark-blue;
        margin-bottom: 10px;
        border-radius: 5px;
        &:focus {
          border-color: $primary-dark-blue;
          box-shadow: 0 0 0 0.5px $primary-dark-blue;
        }
      }

      .instruction-container {
        margin-bottom: 1em;
        color: $primary-blue;
        text-align: center;
      }
      .question-icon {
        margin-right: 5px;
        font-size: 15px;
      }
      .exclusive-code-explanation-button {
        margin-bottom: 30px;
        color: $primary-grey;
      }
      .exclusive-code-explanation {
        color: $primary-blue;
        background-color: $transparent-blue;
        border-radius: 10px;
        padding: 20px 23px 13px 24px;
        margin-bottom: 15px;
        text-align: center;
      }

      .error-message {
        margin: 10px 0;
        color: $error-color;
      }
      .logo-container {
        margin: 10px 0;
        img.logo {
          height: 100px;
        }
      }
    }
  }
}
