@import "../../../../scss/base/colorswatches.scss";

.component-offer-button {
  width: calc(100% - 22px);
  margin: 5px 10px;
  background: #ddd;
  text-align: left;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  box-sizing: border-box;

  .description {
    font-size: 14px;
  }

  .attributes {
    .status-circle {
      display: inline-block;
      height: 11px;
      width: 11px;
      border-radius: 11px;
      background: #bbb;
      margin-right: 10px;

      &.archived {
        background: red;
      }

      &.published-and-not-active {
        background: yellow;
      }

      &.published-and-active {
        background: green;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;

    a {
      font-size: 13px;
    }

    .group-1-actions {
      display: flex;
      gap: 10px;
    }

    .group-2-actions {
      display: flex;
      gap: 10px;
    }
  }

  &.selected {
    background: #4dbd74;
    color: white;

    a {
      color: white;
    }
  }
}
