@import "../../../scss/base/colorswatches.scss";
@import "../../../scss/base/globals.scss";

.component-dashboard-stats {
  border: 1px solid $primary-blue;
  border-radius: 10px;
  .stat {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-right: 1px solid $grey-text;
    padding: 3px;

    .stat-label {
      display: flex;
      align-items: center;
      justify-content: center;

      .stat-label-text {
        text-align: center;
      }
    }

    &:last-child {
      border-right: none;
    }

    @media screen and (max-width: #{$mobile-device-width}) {
      width: 100%;
      border: none;
      margin: 15px 0;
    }
  }
}
