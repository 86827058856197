@import '../../scss/base/colorswatches.scss';

.component-deal-receipt {
  width: 100%;
  max-width: 400px;
  margin: 40px auto;
  border: 1px solid $gray-light-3;
  border-radius: 5px;
  padding: 50px 20px;
  
  .image-area {
    width: 80%;
    margin: 0 auto 10px auto;

    img {
      width: 100%;
    }
  }

  .deal-info {
    text-align: center;
    margin-bottom: 20px;
    
    .description {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
    }
    
    .price {
      font-weight: bold;
    }
  }

  .purchase-info {
    text-align: center;
    margin-bottom: 20px;
    border: 1px solid $fansaves-green;
    border-radius: 4px;
    padding: 10px 0;

    .label {
      font-weight: bold;
      margin-bottom: -10px;
    }

    .code {
      font-size: 40px;
      font-weight: bold;
      color: $fansaves-green;
    }
  }

  .sponsor-info {
    text-align: center;

    .name {

    }

    .address {

    }
  }

  .deal-link-container {
    text-align: center;
    margin-top: 40px;
  }

}