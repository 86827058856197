@import '../../scss/base/colorswatches.scss';

.component-organization-listing {
  width: 360px;

  margin: 20px 0;

  a.image-area {
    height: 300px;
    width: 100%;
    display: block;
    position: relative;

    img.organization-picture {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    img.logo {
      position: absolute;
      width: 50px;
      height: 50px;
      left: 10px;
      bottom: 10px;
      border-radius: 50px;
    }
  }

  .writeup {
    font-size: 14px;
    padding: 10px 0;
    
    .name {
      font-weight: 800;
      color: $primary-color;
    }
    
    .actions {
      a {
        margin-right: 5px;
      }
    }
  }

  &.small {
    width: 195px;

    .image-area {
      height: 195px;
      width: 100%;
      position: relative;
    }
  }
}