.component-offer-preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2000;
  overflow: auto;

  .close-modal-container {
    margin-top: 40px;
    margin-bottom: 10px;

    .close-modal-icon-container {
      color: white;
      text-align: right;

      i {
        cursor: pointer;
      }
    }
  }

  .main-content {
    background: #eee;
    padding: 20px;
    overflow: hidden;

    .caption {
      text-align: center;
      font-size: 16px;
    }

    .offer-info {
      margin: 20px 0;

      .offer-preview-box {
        padding: 10px;
        border: 1px solid #ddd;
        margin: 0 auto;
        width: 400px;
        background: white;
        border-radius: 4px;
        text-align: center;

        .image-container {
          width: 100%;
          height: 200px;
          margin: 10px 0;

          img.offer-picture {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .description {
          font-weight: bold;
          font-size: 18px;
        }

        .sponsor-info {
          margin: 20px 0;

          .sponsor-picture {
            height: 50px;
            width: 50px;
            object-fit: contain;
          }

          .sponsor-name {
          }
        }
      }
    }

    .actions {
      text-align: center;

      button.btn {
        margin: 0 5px;
      }
    }
  }
}
