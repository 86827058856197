@import '../../scss/base/colorswatches.scss';

.component-deal-terms-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
  overflow: auto;

  .close-modal-container {
    margin-top: 40px;
    margin-bottom: 10px;
    
    .close-modal-icon-container {
      color: white;
      text-align: right;

      i {
        cursor: pointer;
      }
    }
  }

  .main-content {
    background: white;
    padding: 20px;

    .header {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .terms-and-conditions {
      margin: 20px 0;
      height: 300px;
      overflow: scroll;
      background: $gray-light-4;
      padding: 10px;
    }
  }

}