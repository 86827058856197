.views-portal-pages-categories{
  .category-container{
    width: fit-content;
    border: solid;
    padding: 1em;
    margin-bottom: 1em;
  }

  .action-buttons{
    text-align: right;

    button{
      margin-left: 0.5em;
    }
  }

  .category-add-component{
    margin-top: 1em;
    display: flex;
    align-items: center;
  }

  .subcategories-container{
    padding-left: 1em;
  }
  
  .category-subcategory-name{
    display: flex;
    align-items: center;
  }
}