@import "../../scss/base/colorswatches.scss";

.component-context-modal {
  .contest-main-content {
    padding: 30px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    .contest-modal-text {
      text-align: center;
    }
  }
}
