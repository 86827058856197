@font-face {
  font-family: 'League Spartan';
  src: url(../../assets/fonts/LeagueSpartan-ExtraLight.woff2) format('woff2');
  font-weight: 200;
}

@font-face {
  font-family: 'League Spartan';
  src: url(../../assets/fonts/LeagueSpartan-Light.woff2) format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'League Spartan';
  src: url(../../assets/fonts/LeagueSpartan-Regular.woff2) format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'League Spartan';
  src: url(../../assets/fonts/LeagueSpartan-Medium.woff2) format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'League Spartan';
  src: url(../../assets/fonts/LeagueSpartan-SemiBold.woff2) format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'League Spartan';
  src: url(../../assets/fonts/LeagueSpartan-Bold.woff2) format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'League Spartan';
  src: url(../../assets/fonts/LeagueSpartan-ExtraBold.woff2) format('woff2');
  font-weight: 800;
}

@font-face {
  font-family: 'League Spartan';
  src: url(../../assets/fonts/LeagueSpartan-Black.woff2) format('woff2');
  font-weight: 900;
}