@import '../../scss/base/globals.scss';
@import '../../scss/base/colorswatches.scss';

.component-search-box {
  
  /* Algolia override Styling */
  .ais-SearchBox {
    .ais-SearchBox-form {
      display: flex;
      width: 100%;

      .ais-SearchBox-input {
        width: calc(100% - 150px);
        height: 50px;
        box-sizing: border-box;
        padding: 0 20px;
        border: 1px solid $gray-light-1;
      }
    
      button.ais-SearchBox-submit {
        width: 150px;
        background: $primary-color;
        color: white;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 $border-radius $border-radius 0;
        font-weight: bold;
      }

      button.ais-SearchBox-reset {
        display: none;
      }
    }
  }

  &.submit-hidden {
    .ais-SearchBox {
      .ais-SearchBox-form {
        display: flex;
        width: 100%;
  
        .ais-SearchBox-input {
          width: 100%;
        }
      
        button.ais-SearchBox-submit {
          display: none;
        }
      }
    }
  }
}