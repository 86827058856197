@import "../../scss/base/colorswatches.scss";

.component-sponsor-map {
  .map {
    width: 100%;
    height: 325px;
  }
  .address-map-placeholder {
    display: flex;
    background-color: $light-grey;
    width: 100%;
    min-height: 325px;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 900px) {
    .map {
      height: 212px;
    }
    .address-map-placeholder {
      min-height: 212px;
    }
  }
}
