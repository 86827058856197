@import "../../scss/base/colorswatches.scss";

.dashboard-deal-card-component {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  .admin-header-text {
    color: $primary-blue;
  }
  .dashboard-deal-card-status {
    white-space: nowrap;
  }
  .dashboard-deal-card-container {
    position: relative;
    width: 100%;
    .deal-card-image-container {
      width: 158px;
      height: 84px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .deal-card-sponsor-container {
      width: 40px;
      align-self: flex-start;
      .deal-card-sponsor-image {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .deal-card-actions-container {
      width: 200px;
    }
    .deal-card-description {
      margin-bottom: 7px;
    }
    .deal-card-content-container {
      flex: 3 1 auto;
      overflow: hidden;
      align-self: flex-start;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      .deal-card-business-name {
        margin-bottom: 10px;
      }
      .deal-card-title-container {
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-bottom: 10px;
        .deal-card-title-icon {
          color: $primary-blue;
          padding: 0 0 20px 10px;
        }
      }
    }
    .deal-card-pin-container {
      position: absolute;
      bottom: 0px;
      right: 20px;
    }
  }
}
