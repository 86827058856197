@import "../../scss/base/colorswatches.scss";

.component-deal-redeemed-info {
  text-align: center;

  .title {
    font-size: 24px;
    font-weight: bold;
    color: $primary-green;
  }

  .official {
    margin-bottom: 20px;
  }

  .deal-redeemed-button {
    margin-top: 40px;
    @media screen and (max-width: 900px) {
      margin-top: 0;
      padding: 11px 25px;
      &.disabled {
        padding: 11px 45px;
      }
    }
  }

  .easy-redemption {
    margin-bottom: 40px;
    @media screen and (max-width: 900px) {
      margin-bottom: 0px;
    }
  }

  .caption {
    margin: 20px 0 40px 0;
    color: $primary-blue;
  }
  .redeem-again-info {
    font-size: 0.9em;
    margin: 10px 0;
  }

  a.website {
    color: $fansaves-green;
  }
}
