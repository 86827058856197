@import "../../../scss/base/colorswatches.scss";

.component-unlock-exclusives {
  min-height: 500px;
  background-image: url(../../../assets/img/SpecialOffersBackground.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &::before {
    content: "";
    position: absolute;
    top: 140px;
    right: 0;
    bottom: -55px;
    left: 0;
    background: rgba(0, 8, 62, 0.7);
  }

  .enter-code-page {
    margin: 0 auto;
    max-width: 799px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 115px auto;
    padding: 40px 70px;
    .message {
      color: #00083e;
      font-family: Inter;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

    .submessage {
      margin: 20px auto;
      color: #00083e;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      max-width: 600px;
    }

    label {
      color: #00083e;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .exclusive-code-input,
    .select-organization {
      color: #00083e;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-radius: 10px;
      border: 1px solid #00083e;
      background: #fff;

      &:hover {
        border: 1px solid #35b729;
      }

      &:focus,
      &:active {
        border: 1px solid #35b729;
        box-shadow: 0 0 0 1px #35b729;
      }
    }

    .error-message {
      margin: 10px 0;
      color: $red-color-dark-1;
    }

    @media (max-width: 900px) {
      padding: 40px 20px;
    }
  }

  .unauthenticated-page {
    text-align: center;
    max-width: 500px;
  }

  .success-page {
    margin: 0 auto;
    max-width: 500px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 115px auto;
    padding: 40px 70px;
    text-align: center;

    .success-icon {
      margin-top: 20px;
      font-size: 150px;
      color: $fansaves-green;
    }

    .success {
      color: #00083e;
      font-family: Inter;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .success-submessage {
      color: #00083e;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 20px auto;
    }

    .actions {
      margin: 20px 0;
    }

    .enter-code-link {
      color: #00083e;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      cursor: pointer;
    }

    @media (max-width: 900px) {
      padding: 40px 20px;
    }
  }

  @media (max-width: 900px) {
    &::before {
      content: "";
      position: absolute;
      top: 140px;
      right: 0;
      bottom: -80px;
      left: 0;
      background: rgba(0, 8, 62, 0.7);
    }
  }
}
