@import '../../scss/base/colorswatches.scss';

.component-special-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  
  text-align: center;
  
  &.exclusive {
    background: $fansaves-green;
  }

  &.flash {
    background: $red-color-dark-1;
  }

  &.not-triggered {
    background: $gray-dark-2;
  }

  &.triggered {
    background: $fansaves-green;
  }

  color: white;

  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;

  i {
    margin-right: 5px;
  }

  &.small {
    font-weight: bold;
    font-size: 13px;
  }
}