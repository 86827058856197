.component-portal-sponsors-deals-form {
  padding-bottom: 50px;
  .redeem-image-preview {
    height: 100px;
    margin-bottom: 10px;
  }

  .margin-bottom-20 {
    margin-bottom: 20px;
  }
}
