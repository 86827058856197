@import '../../../scss//base/globals.scss';
@import '../../../scss/base/colorswatches.scss';

.component-exclusive-code-link-refinement-list {
  a {
    display: block;
    max-width: 500px;
    margin: 20px auto;
    background: $fansaves-green-light-4;
    text-align: center;
    padding: 10px 30px;
    font-weight: bold;
    color: $fansaves-green-dark-2;

    &:hover {
      color: $fansaves-green-dark-1;
    }
  }
}