@import "../../scss/base/colorswatches.scss";

.component-refinement-list {
  .ais-RefinementList {
    ul.ais-RefinementList-list {
      list-style: none;
      padding-left: 0;

      li.ais-RefinementList-item {
        .ais-RefinementList-label {
          .ais-RefinementList-checkbox {
            margin-right: 5px;
          }

          .ais-RefinementList-count {
            background: $gray-light-2;
            color: white;
            border-radius: 15px;
            width: auto;
            height: 19px;
            padding: 0px 8px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
