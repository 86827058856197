@import '../../scss/base/colorswatches.scss';
@import '../../scss/base/globals.scss';

.component-featured-team-tile {
  // Width is set in the parent component because of dependence on margin
  border-radius: 20px;
  box-shadow: 0px 5px 10px rgba(0, 8, 62, 0.15);

  .header-background {
    height: 163px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px 20px 0 0;
    }
  }

  .body {
    text-align: center;
    position: relative;
    padding-top: 80px;

    .logo {
      position: absolute;
      top: -60px;
      left: calc(50% - 60px);
      width: 120px;
      height: 120px;
      border-radius: 50%;
      border: 2px solid $fansaves-blue;
    }

    .name {
      font-weight: 700;
      font-size: 32px;
      padding: 0 10px;
      height: 96px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .featured-brands-caption {
      margin-top: 10px;
      color: $dark-grey;
      font-size: 18px;
    }

    .featured-brands {
      margin-top: 10px;
      margin-bottom: 30px;
      min-height: 80px; //in case no sponsors load

      img.sponsor-picture {
        width: calc((100% - 80px)/4);
        height: 80px;
        object-fit: contain;
        margin: 0 10px;
      }
    }
  }

  .actions {
    background-color: $primary-blue;
    border-radius: 0 0 20px 20px;
    padding: 20px 20px;
    text-align: right;
    color: white;
    font-weight: 700;
    font-size: 24px;
    transition: $transition;
  }

  &:hover {
    .actions {
      background-color: $primary-green;
    }
  }

}