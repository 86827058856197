@import './colorswatches';

a {
  color: $primary-color-light-1;
  text-decoration: none;

  &:hover {
    color: $primary-color;
    text-decoration: none;
  }
}