@import "../../../scss/base/colorswatches.scss";

.component-deal-contest-refinement-list.tab-headers.level-2-headers {
  display: flex;
  margin: 20px 0;

  .tab-header {
    display: inline-block;
    padding: 10px 15px;
    font-size: 14px;
    border-bottom: 2px solid transparent;
    color: $gray;
    border-radius: 5px;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 5px;

    &:hover {
      background-color: $gray-light-4;
    }

    &.selected {
      background-color: $primary-color;
      color: white;
    }
  }

  @media screen and (max-width: 790px) {
    display: block;

    .tab-header {
      display: block;
      text-align: center;
    }
  }
}
