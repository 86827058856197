div.dashboard-chart-container {
  margin: 50px auto 0 auto;
  .component-dashboard-filter {
    position: absolute;
    top: 20px;
    left: 0;
  }
  svg.icon {
    font-size: 20px;
  }
  .dashboard-impressions-chart-actions {
    width: 100%;
    justify-content: flex-end;
    gap: 20px;
    .dashboard-impressions-chart-action {
      display: inline-flex;
      cursor: pointer;
      align-items: center;
      margin-bottom: 50px;
    }
  }
}
