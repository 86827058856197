@import "../../../scss/base/globals.scss";
@import "../../../scss/base/colorswatches.scss";

.views-organizations-show {
  .archived {
    text-align: center;
    max-width: 600px;
    margin: 125px auto 400px auto;
    color: $gray-light-1;
    font-size: 24px;

    .archived-header {
      font-size: 40px;
    }
  }

  .header {
    position: relative;
    height: 315px;

    .header-bg {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .darkened-bg {
      z-index: 2;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 120px;
      background: rgba(0, 0, 0, 0.3);
    }

    .header-summary {
      z-index: 3;
      display: flex;
      position: absolute;
      width: 100%;
      left: 10px;
      bottom: 10px;

      img.logo {
        height: 100px;
        width: 100px;
        object-fit: cover;
        border-radius: 50px;
      }

      .writeup {
        color: white;
        margin-left: 15px;

        .name {
          font-size: 36px;
          font-weight: 800;
          margin: 0;
        }

        .component-follow-organization-link,
        .follow-at-fansaves-com {
          background: $fansaves-green;
          border: 1px solid $fansaves-green;
          color: white;
          display: inline-block;
          padding: 5px 40px;
          border-radius: $border-radius;
          font-weight: bold;
        }

        .component-embed-widget {
          margin-left: 10px;
          font-weight: bold;
        }
      }

      @media screen and (max-width: 500px) {
        display: block;
        text-align: center;
        left: 0;

        .writeup {
          margin-left: 0;

          .name {
            font-size: 24px;
          }

          .embed {
            .embed-dialog {
              position: fixed;
              top: 400px;
              width: calc(100% - 20px);
            }
          }
        }
      }
    }
  }

  .deals-list {
    .search-results {
      ul.ais-Hits-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li.ais-Hits-item {
          margin: 10px;
        }
      }
    }

    .algolia-powered-by-container {
      text-align: center;
    }
  }

  .no-deals {
    text-align: center;
    margin: 100px 0;
    font-size: 16px;
    color: $gray;
  }

  .tab-headers {
    display: flex;
    margin: 20px 0;

    .tab-header {
      display: inline-block;
      padding: 15px 35px;
      font-size: 16px;
      border-bottom: 4px solid transparent;
      color: $gray;
      font-weight: bold;

      &.selected {
        border-color: $primary-color;
        color: $primary-color;
      }

      @media screen and (max-width: 790px) {
        padding: 15px 20px;

        &.long {
          padding: 15px 10px;
        }
      }
    }
  }

  @media screen and (max-width: 790px) {
    .component-deals-list {
      justify-content: center;
    }
  }
}
