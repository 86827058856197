@import "./colorswatches";

.button {
  display: inline-block;
  border-radius: 25px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  color: $primary-white;
  margin: 0;
  transition: background 300ms;
  -webkit-transition: background 300ms;
}

.primary-cta-btn {
  background-color: $primary-green;
  padding: 10px 37px;
  border: 1px solid $primary-green;
  font-weight: 700;
  color: $primary-white;
  font-size: 24px;
  text-transform: uppercase;
  width: 334px;
  height: 54px;

  &:hover,
  &:focus {
    background-color: $primary-green-dark;
    color: $primary-white;
  }

  &.disabled {
    background-color: $primary-white;
    color: $primary-blue;
    border: 1px solid $primary-blue;
    cursor: not-allowed;
  }
  &.disabled.contest {
    background-color: $primary-white;
    color: $primary-green;
    border: 1px solid $primary-green;
    cursor: not-allowed;
  }
}

.popup-cta-btn {
  background-color: $primary-green;
  padding: 8px 66px;
  border: 1px solid $primary-green;
  font-weight: 400;
  font-family: "Rubik";
  color: $primary-white;
  font-size: 20px;
  min-width: 124px;

  &:hover,
  &:focus {
    background-color: $primary-green-dark;
    color: $primary-white;
  }
}

.primary-cta-mobile-btn {
  background-color: $primary-green;
  font-family: "Inter";
  padding: 11px 55px;
  border: 1px solid $primary-green;
  font-weight: 700;
  color: $primary-white;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  width: 324px;
  height: 44px;

  &:hover,
  &:focus {
    background-color: $primary-green-dark;
    color: $primary-white;
  }
  &.disabled {
    background-color: $primary-white;
    color: $primary-blue;
    border: 1px solid $primary-blue;
    cursor: not-allowed;
  }
}

.primary-cta-small-mobile-btn {
  background-color: $primary-green;
  font-family: "Inter";
  padding: 11px 55px;
  border: 1px solid $primary-green;
  font-weight: 700;
  color: $primary-white;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  width: 100%;
  height: 44px;

  &:hover,
  &:focus {
    background-color: $primary-green-dark;
    color: $primary-white;
  }
  &.disabled {
    background-color: $primary-white;
    color: $primary-blue;
    border: 1px solid $primary-blue;
    cursor: not-allowed;
  }
}

.shadow-btn {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.primary-cta-small-mobile-btn.contest {
  padding: 11px 25px;
}

.popup-cta-mobile-btn {
  background-color: $primary-green;
  padding: 10px 36px;
  border: 1px solid $primary-green;
  font-weight: 700;
  font-family: "Rubik";
  color: $primary-white;
  font-size: 16px;
  min-width: 124px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:hover,
  &:focus {
    background-color: $primary-green-dark;
    color: $primary-white;
  }
  &.disabled {
    background-color: $primary-white;
    color: $primary-blue;
    border: 1px solid $primary-blue;
    cursor: not-allowed;
  }
}
