form {
  .form-section {
    margin-bottom: 40px;
  }

  h2 {
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bold;

  }
}