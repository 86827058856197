@import "../../scss/base/colorswatches.scss";

.component-login-form {
  font-family: "Manrope", sans-serif;

  .error {
    color: $error-color;
    font-size: 11px;
    font-weight: 400;
    font-family: "Manrope", sans-serif;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .login-card-group {
    height: 550px;
  }

  .sign-up-card-group {
    height: 570px;
  }

  .login-card {
    height: 100%;
  }

  .login-form {
    height: 100%;
    position: relative;
  }

  .input-group-text {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .login-input {
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    font-family: "Manrope", sans-serif;
    color: $primary-black;
  }

  .login-input::placeholder {
    font-size: 16px;
    font-weight: 400;
    font-family: "Manrope", sans-serif;
    color: #656565;
    opacity: 1;
  }

  .login-input::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 16px;
    font-weight: 400;
    font-family: "Manrope", sans-serif;
    color: #656565;
  }

  .login-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #656565;
    font-size: 16px;
    font-weight: 400;
    font-family: "Manrope", sans-serif;
  }

  .error-input {
    border: 1px solid $red-color;
  }

  .login-button {
    color: $primary-blue;
    background-color: $primary-white;
    border: 1.5px solid $primary-blue;
    min-width: 200px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    &:hover {
      background-color: $primary-white;
      border: 1.5px solid $primary-blue;
      box-shadow: 0px 4px 4px 0px #00000040;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &.disabled {
      color: #00083e66;
      background-color: $primary-white;
      border: 1.5px solid #00083e66;
      cursor: not-allowed;
    }
  }

  .social-login-buttons {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .social-login-title {
    font-size: 14px;
    font-weight: 400;
    font-family: "Manrope", sans-serif;
    color: #656565;
  }

  .forgot-password-button {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .forgot-password {
    font-size: 11px;
    font-weight: 600;
    font-family: "Manrope", sans-serif;
    color: #656565;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .social-icons-button {
    background-color: $primary-white;
    border: 1px solid $primary-white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: $primary-white;
      border: 1px solid $primary-blue;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &.disabled {
      color: $medium-grey;
      border: 1px solid $medium-grey;
      background-color: $primary-white;
      cursor: not-allowed;
    }

    .social-icon {
      margin-right: 10px;
    }
  }

  .margin-bottom-20 {
    margin-bottom: 20px;
  }

  .margin-bottom-50 {
    margin-bottom: 50px;
  }

  .tab-headers.login-form-tab-headers {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    border-bottom: 1.5px solid rgba(101, 101, 101, 1);
    position: relative;
    // margin-bottom: 50px;

    .tab-header {
      display: inline-block;
      padding: 10px 25px;
      font-size: 20px;
      border-bottom: 2px solid transparent;
      color: rgba(84, 78, 78, 1);
      font-weight: 500;

      &.selected {
        font-weight: 700;
        color: $primary-blue;
        position: relative;
        border-bottom: 3px solid $primary-blue;
      }
    }
  }

  .right-side-card {
    border: 1px solid $primary-blue;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .sign-up-checkboxes {
    margin: 5px 0;

    .form-check {
      margin: 10px 0;

      label {
        font-size: 12px;
        font-weight: 400;
        font-family: "Manrope", sans-serif;
        color: #000000b2;

        .underlined-link {
          text-decoration: underline;
        }
      }
    }
  }
}
