@import "../../scss/base/colorswatches.scss";

.component-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1069;
  overflow: auto;

  .close-modal-container {
    margin-top: 40px;
    margin-bottom: 10px;
    max-width: 392px;

    .close-modal-icon-container {
      color: $primary-black;
      float: right;

      .icon-close {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 23px;
        width: 30px;
        height: 30px;
        opacity: 1;
        cursor: pointer;
        background-color: $primary-white;
        color: $primary-black;
        border-radius: 1000px;
        padding-bottom: 2px;

        &:hover {
          opacity: 0.8;
        }
        &:before {
          content: "\2715";
        }
      }
      .blue {
        color: $primary-white;
        background-color: $primary-blue;
      }
    }
  }
  .main-content-container {
    max-width: 392px;
    .main-content {
      background: $primary-white;
      padding: 30px 20px;
      border-radius: 7px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      position: relative;
      min-width: 370px;
    }
    .blue {
      color: $primary-white;
      background-color: $primary-blue;
    }
    .actions {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media screen and (max-width: 900px) {
    .close-modal-container {
      max-width: 274px;
      padding: 0;
    }
    .main-content-container {
      max-width: 274px;
      z-index: 2000;
      .main-content {
        min-width: 257px;
      }
    }
  }
}
