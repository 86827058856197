@import "../../../scss/base/globals.scss";
@import "../../../scss/base/colorswatches.scss";

.component-about-tab {
  min-height: 300px;
  text-align: center;
  background-color: rgba(228, 228, 228, 0.2);
  padding: 50px 25px;

  @media screen and (max-width: $mobile-device-width) {
    padding: 25px 10px;
  }

  .card-container {
    height: 200px;
    width: 100%;
    border-radius: 30px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .organization-description {
    padding: 30px;

    .description {
      display: flex;
      justify-content: center;
      height: 100%;
      padding-right: 3px;

      .description-text {
        height: fit-content;
      }
    }

    .center {
      align-items: center;
    }

    @media screen and (max-width: $mobile-device-width) {
      padding: 20px;
    }
  }

  .organization-socials {
    box-shadow: 5px 10px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 50px 70px;
    border-radius: 30px;
    width: 100%;
    background-color: $primary-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .organization-socials-button {
      height: 100px;

      .social-link-container {
        height: 100%;
      }

      .iconify {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }

      @media screen and (max-width: $tablet-device-width) and (min-width: $mobile-device-width) {
        span {
          font-size: 1em;
        }
      }
      @media screen and (max-width: $mobile-device-width) {
        height: 80px;
        width: calc(50% - 20px);
      }
    }

    @media screen and (max-width: $mobile-device-width) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 25px 10px;
    }
  }
}
