@import '../../scss/base/colorswatches.scss';

.component-sponsor-purchases-dashboard {

  .mode-container {
    margin: 20px 0;
  }

  .stats {
    display: flex;
    margin-top: 1em;

    .stat {
      border: 1px solid white;
      padding: 20px;
      text-align: center;
      margin-right: 10px;
      height: 100px;
      width: 150px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &.payout-stat {
        border-color: $success-color-dark-1;
        color: $success-color-dark-1;
      }

      .label {
        font-size: 12px;
      }

      .value {
        font-size: 24px;
      }
    }
  }

  .offer-section {
    margin: 40px 0 40px 0;

    .offer-description-header {
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .view-header {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: $gray-dark-1;
      margin: 10px 0;
    }

    .offer-info {
      margin-top: 20px;
      margin-bottom: 40px;

      
    }

    .offer-purchases {

    }

  }

  table {
    tr {
      td.purchased-col {
        width: 200px;
      }

      td.code-col {
        width: 200px;
        font-weight: bold;
      }
    }
  }
}