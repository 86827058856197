@import '../../../scss/base/colorswatches.scss';
@import '../../../scss/base/globals.scss';

.component-home {

  font-family: 'League Spartan', sans-serif;

  .hero-banner {
    
    position: relative;
    height: 693px;
    
    img.hero-banner-bg {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
      z-index: 1;
    }

    .hero-banner-content {
      position: relative;
      z-index: 2;
      height: 100%;

      .component-default-container {
        height: 100%;

        .slogan-and-call-to-action {
          position: absolute;
          left: 90px;
          top: 174px;
          color: $primary-blue;
          z-index: 2;
          max-width: 536px;
          
          .slogan-box {
            .slogan {
              font-size: 64px;
              font-weight: 800;
              line-height: 120%;
            }
            
            .subslogan {
              font-size: 24px;
              margin: 20px 0 20px 0;
              z-index: 1;
              font-weight: 600;
            }
          }
    
    
          .call-to-action {
            margin: 40px 0;
    
            .component-button {
              font-size: 24px;
              padding: 15px 20px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1200px) {
      .hero-banner-content {  
        .component-default-container {
  
          .slogan-and-call-to-action {
            left: 0;
            top: 80px;
            max-width: 450px;

            .slogan-box {              
              .slogan {
                font-size: 60px;
              }
              
              .subslogan {
                font-size: 20px;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: #{$mobile-device-width}) {
      height: 500px;

      img.hero-banner-bg {
        left: -300px;
        width: calc(100% + 300px);
        opacity: 0.4;
      }

      .hero-banner-content {  
        .component-default-container {
  
          .slogan-and-call-to-action {
            text-align: center;
            max-width: 100%;
            top: 100px;

            .slogan-box {              
              .slogan {
                font-size: 45px;
              }
              
              .subslogan {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }

  .carousel-root {
    .carousel.carousel-slider {
      .control-dots {
        .dot {
          height: 14px;
          width: 14px;
          margin: 0 4px;
          opacity: 1;
          box-shadow: none;
          background: $light-grey;
          &.selected {
            background: $primary-blue;
          }
        }
      }
    }
  }

  .home-page-section {
    margin: 80px 0;

    .pre-header-caption {
      font-weight: 600;
      text-align: center;
      color: $primary-blue;
      margin-bottom: 14px;
      font-size: 24px;
    }

    h2 {
      font-weight: 800;
      text-align: center;
      color: $primary-blue;
      font-size: 48px;
      margin-bottom: 60px;
    }
  }

  .categories-section {
    .tiles {
      margin-bottom: 20px;
      display: flex;
      @media screen and (max-width: #{$mobile-device-width}) {
        display: block;
      }
    }

    .button-container {
      text-align: center;
      margin-top: 60px;

      .component-button {
        font-size: 24px;
        padding: 20px 40px;
      }
    }
  }

  .how-it-works-section {
    .component-default-container {
      display: flex;
    }

    .video-container {
      width: 55%;
      padding: 0 80px;
      display: flex;

      video {
        margin: 0 auto;
        width: calc(100% - 20px);
      }
    }

    .text-container {
      width: 45%;

      h2 {
        text-align: left;
      }

      .steps {
        .step {
          padding-left: 20px;
          border-left: 2px solid transparent;
          margin-bottom: 50px;

          .step-description {
            color: $dark-grey;
            font-weight: 600;
            font-size: 32px;
          }

          .step-details {
            color: $dark-grey;
            font-size: 20px;
            font-weight: 400;
          }

          &.active {
            border-color: $primary-green;

            .step-description {
              color: $primary-blue;
            }
  
            .step-details {
              color: $primary-blue;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1050px) {
      .video-container {
        width: 55%;
        padding: 0 10px;
        display: flex;
  
        video {
          margin: 0 auto;
          width: calc(100% - 20px);
        }
      }

      .text-container {
        .steps {
          margin-top: 40px;
  
          .step {
            .step-description {
              font-size: 24px;
            }
  
            .step-details {
              font-weight: 400;
            }
          }
        }
      }
    }

    @media screen and (max-width: #{$mobile-device-width}) {
      .component-default-container {
        display: block;
      }

      .video-container {
        width: 100%;
      }

      .text-container {
        h2 {
        text-align: center;
        }

        margin-top: 50px;
        width: 100%;
      }
    }
  }

  .fan-of-fansaves-section {
    .fan-of-fansaves-tiles {
      display: flex;
      
      .tile {
        flex-grow: 1;
        box-shadow: 0px 0px 20px rgba(0, 8, 62, 0.1);
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        padding: 20px;
        width: calc((100% - 80px) / 3);
        font-size: 24px;
        color: $primary-blue;

        margin: 0 20px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-left: 0;
        }
        
        .write-up {
          margin-top: 40px;
        }
      }
    }

    @media screen and (max-width: #{$mobile-device-width}) {
      .fan-of-fansaves-tiles {
        display: block;
  
        .tile {
          width: 100%;
          margin: 10px 0;
        }
      }
    }
  }
  
  .media-logos-section {
    margin: 80px 0;
    
    .component-default-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
  
      a {
        margin: 0 20px;
        width: calc(14% - 40px);
  
        &.csmg-logo-link {
          margin-top: -1.5%;
        }
  
        img {
          width: 100%;
        }
  
        @media screen and (max-width: 910px) {
          width: calc(32% - 40px);
          &.csmg-logo-link {
            margin-top: auto;
          }
        }
      }
    }
  }

  .search-bar-section {
    position: relative;
    padding: 100px 0;
    margin: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      opacity: 0.5;
    }

    .search-bar-box {
      background: white;
      border-radius: 5px;
      padding: 20px 40px 50px 40px;
      max-width: 730px;
      margin: 0 auto;
      box-shadow: 0px 0px 20px rgba(0, 8, 62, 0.3);

      .search-bar-caption {
        margin: 20px;
        text-align: center;
        color: $primary-blue;
        font-size: 32px;
        font-weight: 700;
      }

      .component-portable-search-bar {
        max-width: 530px;
        margin: 0 auto;
      }
    }
  }
}
