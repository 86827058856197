/* ----------- Current Swatches ----------------------- */

/* Brand Colours */
$primary-blue: #00083e;
$primary-blue-transparent: rgba(0, 8, 62, 0.7);
$secondary-blue: #000f80;
$primary-green: #35b729;
$primary-green-light: #4cc441;
$primary-green-dark: #25a919;
$primary-blue: #00083e;
$primary-white: #ffffff;
$primary-black: #000000;
$primary-grey: #2b2b32;
$primary-grey-light: #e3e3e3;
$transparent-blue: rgba(116, 124, 177, 0.06);
$transparent-black: rgba(0, 0, 0, 0.75);
$primary-dark-blue: #01073b;
$hover-grey: rgba(0, 7, 56, 0.2);
$grey-text: #464646;

/* SHADES */
$dark-grey: #7e7f86;
$medium-grey: #959595;
$light-grey: #dfdfe0;
$light-blue: #dde5ff;

/* Box Shadows */
$primary-green-30: rgba(53, 183, 41, 0.3);
$dark-green-25: rgba(21, 73, 16, 0.25);
$dark-green-15: rgba(21, 73, 16, 0.15);
$grey: rgba(126, 127, 134, 0.2);
$blue-30: rgba(0, 8, 62, 0.3);
$blue-15: rgba(0, 8, 62, 0.15);
$blue-10: rgba(0, 8, 62, 0.1);
$light-blue-25: rgba(221, 229, 255, 0.25);

/* ----------- Legacy Swatches ----------------------- */
$fansaves-green-dark-2: #106109;
$fansaves-green-dark-1: #219117;
$fansaves-green: #35b729;
$fansaves-green-light-1: #4cc441;
$fansaves-green-light-2: #84e07b;
$fansaves-green-light-3: #bdf5b8;
$fansaves-green-light-4: #e5ffe3;

$fansaves-blue: #00083e; /* Note, this is the same $primary-color right now */
$fansaves-blue-light-1: #000a4d;

$primary-color-light-2: #dde5ff;
$primary-color-light-1: #000f80;
$primary-color: #00083e;
$primary-color-dark-1: #000420;

$red-color-dark-1: #990000;
$red-color: #e80505;

$gray-dark-2: #333333;
$gray-dark-1: #555555;
$gray: #777777;
$gray-light-1: #999999;
$gray-light-2: #aaaaaa;
$gray-light-3: #cccccc;
$gray-light-4: #ededed;
$gray-light-5: #fafafa;

$success-color-light-3: #f1fff1;
$success-color: #009900;
$success-color-dark-1: #005500;

$error-color-light-3: #ffeeee;
$error-color-light-2: #ffdddd;
$error-color-light-1: #ffaaaa;
$error-color: #ff0000;
$error-color-dark-1: #aa0000;
$error-color-dark-2: #880000;
$error-color-dark-3: #550000;
