.team-marker-info-window-with-follow-button {
  width: 168px;
  background: #00083e;
  padding: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  // make it shorter when mobile
  @media (max-width: 768px) {
    width: 120px;
  }
}
