@import '../../../scss/base/colorswatches.scss';

.views-pages-how-it-works {
  .banner {
    overflow: hidden;
    background: $fansaves-blue;
    position: relative;
    height: 265px;

    .slogan {
      position: relative;
      color: white;
      font-weight: 800;
      font-size: 30px;
      margin: 80px 40px;
      text-align: center;
      width: 70%;
      z-index: 2;

      img.logo {
        height: 60px;
      }
    }

    img.fansaves-excited-girl {
      height: 320px;
      position: absolute;
      right: 50px;
      bottom: -30px;
      z-index: 1;
    }
  }

  .caption {
    text-align: center;
    margin: 50px 0;
    font-weight: 800;
    font-size: 24px;
  }

  .steps {
    display: flex;

    .step {
      display: flex;
      align-items: center;
      margin: 0 30px;


      .step-number {
        margin-right: 20px;
        font-size: 40px;
        font-weight: 800;
      }

      .step-body {
        font-size: 16px;
        color: $gray-dark-1;
      }
    }
  }

  .benefits {
    display: flex;
    margin: 100px 0;
    
    .benefit {
      margin: 0 30px;
      text-align: center;
      font-size: 16px;
      color: $gray-dark-1;

      .icon-container {
        height: 100px;
        width: 100px;
        margin: 20px auto;

        svg {
          height: 100%;
          width: 100%;

          path {
            fill: $fansaves-blue;
          }
        }
      }

      .title {
        text-decoration: underline;
        font-size: 20px;
      }

      .action {
        margin: 20px 0;
      }
    }
  }

  @media screen and (max-width: 890px) {
    .banner {
      .slogan {
        text-align: left;
        margin: 40px;
      }

      img.logo {
        height: 60px;
      }
    }
  }

  @media screen and (max-width: 830px) {
    .steps {
      display: block;

      .step {
        margin: 30px;
      }
    }

    .benefits {
      display: block;

      .benefit {
        margin: 75px 30px;
      }
    }
  }

  @media screen and (max-width: 715px) {
    .banner {
      img.fansaves-excited-girl {
        right: -10%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .banner {
      .slogan {
        font-size: 24px;
        margin: 20px;
  
        img.logo {
          height: 40px;
        }
      } 

      img.fansaves-excited-girl {
        height: 200px;
        right: -10%;
      }
    }
  }
}