@import "../../../scss/base/colorswatches.scss";
@import "../../../scss/base/globals.scss";

.component-dashboard-header {
  position: relative;
  width: 100%;

  img.dashboard-header-logo {
    width: 118px;
    height: 118px;
    object-fit: contain;
    border-radius: 50%;
  }
  img.dashboard-header-logo.small {
    width: 76px;
    height: 76px;
    object-fit: contain;
    border-radius: 50%;
  }

  .dashboard-header-links {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    top: 0px;
    right: 0px;
    color: $primary-blue;
    .link-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .rubik-h3 {
        color: $primary-blue;
      }
    }
  }
}
