@import '../../scss/base/globals.scss';
@import '../../scss/base/colorswatches.scss';

.component-portable-search-bar {
  width: 100%;
  display: flex;

  $search-bar-height: 37px;

  .search-terms {
    width: 100%;
    height: $search-bar-height;
    box-sizing: border-box;
    padding: 0 20px;
    border: 1px solid $medium-grey;
    border-right: none;
    border-radius: $border-radius 0 0 $border-radius;
    font-size: 18px;
  }

  a.search-button {
    width: 49px;
    background: $fansaves-green;
    color: white;
    height: $search-bar-height;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 $border-radius $border-radius 0;
    font-weight: bold;
    border: 1px solid $medium-grey;
    border-left: none;
  }
}