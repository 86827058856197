@import '../../scss/base/colorswatches.scss';

.component-followed-organizations {
  display: flex;
  border: 1px solid $gray-light-3;
  overflow-x: scroll;
  padding: 15px;

  .component-organization-icon-link {
    margin-right: 15px;
  }

  .follow-more {
    display: flex;
    height: 80px;
    width: 80px;
    border-radius: 40px;
    border: 3px solid $gray-light-3;
    background: $gray-light-4;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    color: $gray-light-3;
  }
}