@import '../../../scss/base/colorswatches.scss';

.component-selectable-list-item {
  display: flex;
  padding: 10px 0;
  cursor: pointer;

  .circle-container {
    width: 30px;

    .circle {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border: 1px solid $fansaves-green;
      display: flex;
      align-items: center;
      justify-content: center;

      &.selected {
        color: white;
        background-color: $fansaves-green;
      }
    }
  }
}