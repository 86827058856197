@import "../../scss/base/colorswatches.scss";

.component-exclusive-overlay {
  position: absolute;
  top: 10px;
  right: 0;
  left: auto;
  color: $primary-white;
  margin-right: 10px;

  @media screen and (max-width: 900px) {
    img {
      height: 21px;
      width: 21px;
    }
  }
}
