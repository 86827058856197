@import '../../scss/base/colorswatches.scss';

.component-organization-icon-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;

  img.organization-logo {
    height: 80px;
    width: 80px;
    border-radius: 40px;
    border: 3px solid $fansaves-green;
    object-fit: contain;
  }

  .name {
    margin-top: 5px;
    text-align: center;
    font-size: 12px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}