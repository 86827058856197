@import '../../../scss/base/colorswatches.scss';

.views-organizations-list {
  .slogan {
    font-size: 38px;
    text-align: center;
    color: $gray-light-1;
    max-width: 800px;
    margin: 20px auto 60px auto;
  }

  .section {
    margin-bottom: 50px;

    h2 {
      margin-bottom: 20px;
    }
  }

  .no-organizations {
    margin: 80px 0;
    text-align: center;
    color: $gray-light-1;
    font-size: 20px;
    font-style: italic;
  }
}