.component-deals-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
  .component-deal-listing {
    margin: 10px;

    &.small {
      margin: 10px 17px;
    }
  }
}