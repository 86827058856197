@import '../../../scss/base/colorswatches.scss';
@import '../../../scss/base/globals.scss';

.component-category-tile {
  $tile-border-radius: 5px;

  border-radius: $tile-border-radius $tile-border-radius $tile-border-radius $tile-border-radius;
  box-shadow: 0px 0px 10px rgba(0, 8, 62, 0.15);

  img {
    border-radius: $tile-border-radius $tile-border-radius 0 0;
    object-fit: cover;
    height: 200px;
    width: 100%;
  }

  .body {
    text-align: center;
    padding: 20px;

    .title {
      font-size: 32px;
      font-weight: 700;
      color: $primary-blue;

      a {
        color: $primary-blue;
      }
    }

    .description {
      border-radius: 0 0 $tile-border-radius $tile-border-radius;
      color: $primary-blue;
      max-width: 470px;
      margin: 10px auto;
      text-align: center;
      font-size: 24px;
      line-height: 34px;

      .subcategory {
        display: inline-block;
      }

      .separator {
        margin: 0 5px;
      }
    }
  }

  &.half-tile {
    width: 50%;
    margin: 0 10px;
    
    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  &.third-tile {
    width: 33.333%;
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: #{$mobile-device-width}) {
    &.half-tile, &.third-tile {
      width: 100%;
      margin: 10px 0;
    }
  }
}