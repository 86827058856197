@import "./colorswatches.scss";

body {
  font-family: "Manrope", sans-serif;

  .emphasize {
    font-weight: 800;
  }

  .standard-h1 {
    margin: 0;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 3%;
    text-shadow: 0px 4px 4px 0px #00000040;
    font-family: "Inter";
    line-height: 39px;
  }

  .standard-h2 {
    margin: 0;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 3%;
    font-family: "Inter";
    line-height: 39px;
  }

  .standard-h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 3%;
    font-family: "Rubik";
    line-height: 28px;
  }

  .standard-h4 {
    margin: 0;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 3%;
    font-family: "Rubik";
    line-height: 28px;
  }

  .standard-h5 {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 3%;
    font-family: "Inter";
    line-height: 24px;
  }

  .standard-h6 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 3%;
    font-family: "Inter";
    line-height: 24px;
  }

  .standard-h8 {
    margin: 0;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 3%;
    font-family: "Rubik";
    line-height: 28px;
  }

  .standard-h9 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 4%;
    font-family: "Rubik";
    line-height: 24px;
  }

  .standard-b1 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    font-family: "Rubik";
    line-height: 19px;
  }
  .standard-b1-small {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    font-family: "Rubik";
    line-height: 19px;
  }

  .standard-b2 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    font-family: "Rubik";
    line-height: 19px;
  }

  .standard-b2-small {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    font-family: "Rubik";
    line-height: 19px;
  }

  .standard-b3 {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    font-family: "Rubik";
    line-height: 14px;
    color: $primary-grey;
  }

  .standard-b4 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    font-family: "Rubik";
    line-height: 19px;
    color: $primary-grey;
  }

  .mobile-h1 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 3%;
    font-family: "Inter";
    line-height: 24.2px;
  }
  .mobile-h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 3%;
    font-family: "Inter";
    line-height: 24.2px;
  }

  .mobile-h2-small {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 3%;
    font-family: "Inter";
    line-height: 19px;
  }

  .mobile-h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 3%;
    font-family: "Rubik";
    line-height: 24px;
  }
  .mobile-h4 {
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 4%;
    font-family: "Inter";
    line-height: 15px;
  }
  .mobile-h5 {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 4%;
    font-family: "Inter";
    line-height: 15px;
  }
  .mobile-b1 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 3%;
    font-family: "Rubik";
    line-height: 19px;
  }
  .mobile-b2 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 3%;
    font-family: "Rubik";
    line-height: 19px;
  }
  .mobile-b3 {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    font-family: "Rubik";
    line-height: 14px;
  }
  .mobile-b4 {
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    font-family: "Rubik";
    line-height: 14px;
  }
  .mobile-b5 {
    margin: 0;
    font-size: 11px;
    font-weight: 400;
    font-family: "Inter";
    line-height: 13px;
  }
  .public-h1 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 3%;
    font-family: "Public Sans", sans-serif;
    line-height: 19px;
    color: $primary-black;
  }
  .public-h1-bold {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 3%;
    font-family: "Public Sans", sans-serif;
    line-height: 19px;
    color: $primary-black;
  }

  .public-h1-small {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 3%;
    font-family: "Public Sans", sans-serif;
    line-height: 19px;
    color: $primary-black;
  }

  .public-h2 {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    font-family: "Inter";
    line-height: 16px;
    letter-spacing: 0.04em;
    color: $primary-black;
  }
  .rubik-h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    font-family: "Rubik";
    line-height: 19px;
    letter-spacing: 0.04em;
    color: $primary-black;
  }
  .rubik-h4 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    font-family: "Rubik";
    line-height: 24px;
    letter-spacing: 0.04em;
    color: $primary-black;
  }

  .rubik-h6 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    font-family: "Rubik";
    line-height: 24px;
    letter-spacing: 0.04em;
    color: $primary-black;
  }

  .rubik-h5 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    font-family: "Rubik";
    line-height: 17px;
    letter-spacing: 0.04em;
    color: $primary-black;
  }
  .inter-b1 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    font-family: "Inter";
    line-height: 19px;
    letter-spacing: 0.04em;
    color: $primary-black;
  }
  .inter-b2 {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    font-family: "Inter";
    line-height: 15px;
    letter-spacing: 0.04em;
    color: $primary-black;
  }
  .roboto-b3 {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    line-height: 14px;
    color: $primary-black;
  }

  .blue {
    color: $primary-blue;
  }
  .underline {
    text-decoration: underline;
  }
}
