@import '../../scss/base/colorswatches.scss';

.component-loading-placeholder {
  background-color: $gray-light-2;
  animation-name: colorChange;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;

  @keyframes colorChange {
    0% {
      background-color: $gray-light-2;
    }

    100% {
      background-color: $gray-light-4;
    }
  }
}