@import "../../../scss/base/globals.scss";
@import "../../../scss/base/colorswatches.scss";

.organization-browser-component {
  .search-bar-container {
    margin: 10px 0 30px 0;
  }

  .search-results {
    display: flex;

    .toggle-mobile-filters {
      display: none;
    }

    .sort-and-filters {
      box-sizing: border-box;
      width: 260px;
      border-right: 1px solid $gray-light-1;
      padding-right: 20px;

      .section {
        margin-bottom: 20px;

        h2 {
          text-transform: uppercase;
          color: $gray;
          font-weight: 800;
          font-size: 16px;
        }

        h3 {
          font-size: 1em;
          margin-bottom: 10px;
          color: $gray;
        }

        .filter-section {
          margin: 15px 0;

          .filter-checkbox {
            margin-right: 5px;
          }

          /* Aloglia Styling*/
          .ais-RefinementList {
            .ais-RefinementList-list {
              margin-bottom: 0;
            }
            .ais-RefinementList-showMore {
              border: none;
              padding: 0;
              margin: 0;
              background: none;
              color: $primary-color-light-1;

              &.ais-RefinementList-showMore--disabled {
                display: none;
              }

              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }

    .results {
      box-sizing: border-box;
      width: calc(100% - 260px);
      padding-left: 20px;

      .results-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        h2 {
          text-transform: uppercase;
          color: $gray;
          font-weight: 800;
          font-size: 16px;
        }
      }

      /* Algolia Styling */
      .ais-Hits {
        ul.ais-Hits-list {
          list-style: none;
          padding-left: 0;

          display: flex;
          flex-wrap: wrap;

          .ais-Hits-item {
            margin-right: 10px;
          }
        }
      }

      .algolia-powered-by-container {
        padding: 10px 0;
        text-align: center;
      }
    }
  }

  .component-results-with-empty-state.empty-state {
    text-align: center;
    padding: 80px 0;
    font-size: 24px;
    color: $gray-light-2;
  }

  @media screen and (max-width: 850px) {
    .search-bar-container {
      margin: 10px 0 10px 0;
    }

    .search-results {
      display: block;

      .toggle-mobile-filters {
        display: block;
        cursor: pointer;
        text-align: center;
        padding: 10px 0;
        background: $gray-light-3;
      }

      .sort-and-filters {
        width: 100%;
        padding: 20px;
        border: none;
        background: $gray-light-4;

        &.show-mobile {
          display: block;
        }

        &.hide-mobile {
          display: none;
        }
      }

      .results {
        width: 100%;
        padding: 0;
        justify-content: center;
        margin-top: 20px;

        /* Algolia Styling */
        .ais-Hits {
          ul.ais-Hits-list {
            justify-content: center;
          }
        }
      }
    }
  }
}
