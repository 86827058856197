.component-suggested-organizations {
  max-height: 100vh;
  overflow: scroll;

  .suggestion-header {
    margin-bottom: 20px;
  }
  
  .organization {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    
    a {
      width: calc(100% - 100px);
      display: flex;
      align-items: center;
      
      .logo-area {
        width: 40px;
        height: 40px;
  
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
  
      .name {
        padding: 0 20px;
        width: calc(100% - 40px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: bold;
      }
    }

    .actions {
      width: 100px;
    }
  }
}