@import "../../scss/base/colorswatches.scss";

.component-in-store-redeem-modal {
  .main-content {
    margin-bottom: 300px;
    padding-bottom: 25px;
    position: relative;

    .business-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: 20px;
      gap: 15px;
      .business-logo-container {
        width: 51px;
        flex: 1;
        .business-logo {
          max-width: 100%;
          object-fit: contain;
        }
      }
      .business-details {
        flex: 4;
      }
    }
    .deal-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      margin: 10px 5px;
      .description {
        color: $primary-blue;
        margin: 38px 0;
      }
      .deal-terms {
        width: 100%;
      }
    }
    .image-container {
      width: calc(100% + 40px);
      height: 218px;
      margin-left: -20px;
      img.deal-picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .redeem-disclaimer-container {
      margin: 10px 0 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $primary-grey;

      .redeem-disclaimer-text {
        background-color: $transparent-blue;
        text-align: center;
        border-radius: 10px;
        padding: 5px 10px;
        margin-top: 14px;
        width: 90%;
        .online-redeem-link {
          color: $primary-green;
          &:hover {
            text-decoration: none;
          }
        }

        .redeem-online-code-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          width: 100%;

          .redeem-online-code {
            text-align: center;
            padding: 0 30px;
          }
        }
        a {
          color: $primary-black;
          margin: 0 3px;
          text-decoration: underline;
        }
      }
    }

    .redeemed-important-text {
      color: $primary-blue;
    }

    .deal-redeemed-container {
      background-color: $primary-green;
      color: $primary-white;
      position: absolute;
      top: 0;
      left: 0;
      height: 55px;
      width: 100%;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      padding: 0 17px;
      .deal-redeemed-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;
      }
      .fansaves-logo {
        width: 118px;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: center;
        padding: 4px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .deal-redeemed-time {
      margin-top: 30px;
      text-align: center;
    }
    .redeemed-business-container {
      margin-top: 35px;
    }
  }
}
