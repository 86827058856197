@import '../../scss/base/colorswatches.scss';
@import '../../scss/base/globals.scss';

.component-featured-teams {
  .featured-team-slide {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
  }

  .component-organization-link {
    margin: 0 10px;
    width: calc((100% - 60px) / 3);

    .component-featured-team-tile {
      width: 100%;
    }
  }

  .default-featured-teams-container {
    display: block;
  }

  .mobile-featured-teams-container {
    display: none;

    .component-organization-link {
      margin: 0;
      width: 100%;
    }
  }

  @media screen and (max-width: #{$mobile-device-width}) {
    .default-featured-teams-container {
      display: none;
    }

    .mobile-featured-teams-container {
      display: block;
    }
  }
  
}