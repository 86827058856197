@import "../../scss/base/colorswatches.scss";

.component-deal-listing {
  display: block;
  width: 360px;
  margin: 20px 0;

  .organization-business-header {
    display: flex;
    justify-content: space-between;

    .organization-info,
    .business-info {
      img {
        height: 80px;
        width: 80px;
        margin: 10px;
        object-fit: contain;
      }
    }

    .business-info {
      display: flex;
      align-items: center;

      .business-name {
        font-weight: bold;
        color: $gray-dark-2;
      }

      .business-address {
        color: $gray-dark-2;
      }
    }
  }

  .image-area {
    height: 300px;
    width: 100%;
    position: relative;

    img.deal-picture {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .details {
    display: flex;

    .sponsor-logo-container {
      width: 60px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding-top: 10px;

      img.sponsor-picture {
        width: 100%;
        object-fit: contain;
      }
    }

    .writeup {
      width: calc(100% - 100px);
      padding-top: 10px;
      margin-left: 15px;

      .description {
        font-weight: 800;
        font-size: 16px;
        text-transform: uppercase;
        color: $primary-color-light-1;

        &.feed {
          margin-top: 10px;
          font-size: 18px;
        }
      }

      .contest-deal-description {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 3%;
        font-family: "Rubik", sans-serif;
        line-height: 28px;
      }
      .sponsor-name {
        font-size: 16px;
        margin-bottom: 1px;
      }

      .address {
        color: $gray;
        font-size: 13px;
      }
    }

    .writeup.contest-deal {
      width: 100%;
    }

    .redeem-button {
      font-size: 12px;
      background: $fansaves-green;
      color: white;
      text-transform: uppercase;
      padding: 5px 10px;
      font-weight: bold;
      border-radius: 20px;
      margin: 5px 0 0 0;
    }
    .redeem-button.contest-deal-entered {
      background: $primary-white;
      color: $fansaves-green;
      border: 1px solid $fansaves-green;
    }
  }

  .details.contest-deal-details {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .redeem-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &.feed {
    width: 100%;
    border: 1px solid $gray-light-3;
    padding-bottom: 10px;

    .image-area {
      height: 450px;

      .expired-image-area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        background-color: $primary-blue-transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $primary-white;
        padding: 15px;

        .expired-deal-title {
          text-align: center;
          margin-top: 20px;
          text-transform: uppercase;
        }

        .expired-deal-icon {
          width: 35px;
          height: 35px;
          font-size: 35px;
          filter: drop-shadow(0px 4px 4px $primary-black);
          -webkit-filter: drop-shadow(0px 4px 4px $primary-black);
        }
      }

      @media screen and (max-width: 900px) {
        height: 400px;
      }
      @media screen and (max-width: 800px) {
        height: 450px;
      }
    }

    .redeem-button {
      font-size: 14px;
      padding: 10px 20px;
      border-radius: 20px;
      margin: 20px 15px 0 0;
    }
  }

  &.medium {
    width: 250px;

    .image-area {
      height: 250px;
      width: 100%;
      position: relative;
    }
    .details {
      .sponsor-logo-container {
        width: 40px;
      }

      .writeup {
        width: calc(100% - 40px);
        padding-top: 10px;
        margin-left: 10px;

        .description {
          font-size: 13px;
          text-transform: uppercase;
          color: $primary-color-light-1;
        }

        .contest-deal-description {
          text-align: center;
        }

        .sponsor-name {
          font-size: 14px;
        }

        .address {
          font-size: 13px;
        }
      }

      .writeup.contest-deal {
        width: 100%;
      }
    }
  }

  &.small {
    width: 195px;

    .image-area {
      height: 195px;
      width: 100%;
      position: relative;
    }

    .details {
      .sponsor-logo-container {
        width: 40px;
      }

      .writeup {
        width: calc(100% - 40px);
        padding-top: 10px;
        margin-left: 10px;

        .description {
          font-size: 12px;
        }

        .sponsor-name {
          font-size: 13px;
        }

        .address {
          font-size: 12px;
        }
      }

      .writeup.contest-deal {
        width: 100%;
      }
    }
  }
  .pin-deal-icon {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 10;
    width: 40px;
  }
}
