@import "../../../scss/base/globals.scss";
@import "../../../scss/base/colorswatches.scss";

a.component-button,
a.component-button:not([href]) {
  display: inline-block;
  border-radius: $border-radius;
  padding: 10px;
  cursor: pointer;
  transition: $transition;
  font-weight: bold;
  border: 1px solid transparent;

  &.large-padding {
    padding: 20px;
  }

  &.non-bold {
    font-weight: normal;
  }

  &.wide {
    padding: 5px 40px;
  }

  &.small {
    padding: 5px 10px;
  }

  &.medium {
    padding: 5px 20px;
  }

  &.rounded {
    border-radius: 20px;
  }

  &.rounded-20 {
    border-radius: 20px;
  }

  &.rounded-10 {
    border-radius: 10px;
  }

  &.shadow {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  &.primary {
    background: $primary-color;
    color: white;

    &:hover {
      background: $primary-color;
      color: white;
    }
  }

  &.blue {
    background: $primary-blue;
    color: white;

    &:hover {
      background: $primary-green;
      color: white;
    }
  }

  &.green {
    background: $primary-green;
    color: white;

    &:hover {
      background: $primary-green-light;
      color: white;
    }
  }

  &.green-continue {
    background: $primary-green-dark;
    color: white;

    &:hover {
      background: $primary-green-dark;
      color: white;
      box-shadow: 0px 4px 4px 0px #00000040;
    }

    &.disabled {
      background: #e9f6e8;
      color: $primary-green-dark;
      cursor: not-allowed;
    }
  }

  &.green-outline {
    background: transparent;
    border-color: $primary-green;
    color: $primary-green;
  }

  &.green-outline-continue {
    background: transparent;
    border-color: $primary-green-dark;
    color: $primary-green-dark;

    &:hover {
      box-shadow: 0px 4px 4px 0px #00000040;
      color: $primary-green-dark;
      background: transparent;
      border-color: $primary-green-dark;
    }

    &.disabled {
      background: #e9f6e8;
      color: $primary-green-dark;
      cursor: not-allowed;
    }
  }

  &.light-blue {
    background: $light-blue;
    color: $primary-blue;

    &:hover {
      background: white;
    }
  }

  &.light-blue-outline {
    background: transparent;
    border-color: $light-blue;
    color: $light-blue;
  }

  &.error {
    background: $error-color-dark-3;
    color: white;

    &:hover {
      background: $error-color-dark-2;
      color: white;
    }
  }

  &.white-outline {
    border: 1px solid white;
    color: white;
    background: transparent;
  }

  &.white {
    background: white;
    color: $fansaves-green;

    &:hover {
      background: $fansaves-green-light-1;
      color: white;
    }
  }

  &.ghost-green {
    color: $fansaves-green;

    &:hover {
      background: $fansaves-green-light-1;
      color: white;
    }
  }

  &.ghost-blue {
    color: $fansaves-blue;

    &:hover {
      background: $fansaves-blue-light-1;
      color: white;
    }
  }

  &.red {
    background: $red-color;
    color: white;

    &:hover {
      background: $red-color-dark-1;
      color: white;
    }
  }

  &.ghost-red {
    color: $red-color-dark-1;

    &:hover {
      background: $error-color-dark-2;
      color: white;
    }
  }
}
