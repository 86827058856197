@import "../../scss/base/colorswatches.scss";

.component-countdown {
  color: $primary-blue;
  text-align: center;
  .countdown-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    margin: 20px 0 40px 0;
  }
  .full-text {
    margin-bottom: 10px;
  }
}
