// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";

// Core UI
@import "~@coreui/coreui/scss/coreui.scss";
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

// Default FanSaves App
@import "../../../scss/app";

@import "../../../scss/base/colorswatches.scss";
@import "../../../scss/base/globals.scss";

.component-default-layout {
  background: white;

  .default-header {
    font-family: "League Spartan", sans-serif;

    .component-default-container {
      height: 140px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .logo-container {
        width: 265px; //logo width + 30px

        img.logo {
          width: 235px;
        }
      }

      .nav-actions {
        .nav-links {
          padding: 10px 0 10px 0;

          a {
            margin: 0 15px;
            color: $dark-grey;
            font-size: 18px;
            cursor: pointer;

            &:hover {
              color: $primary-color;
            }

            &.component-button.green {
              color: white;
            }

            &.unlock-exclusive-link {
              font-weight: 700;
              color: $fansaves-green;
            }
          }

          .submenu-group {
            position: relative;
            display: inline-block;
            padding: 20px 0;

            .submenu {
              position: absolute;
              left: -99999px;
            }

            &:hover {
              .submenu {
                left: 0;
                top: 50px;
                background: white;
                border-top: 2px solid $primary-green;
                box-shadow: 0px 0px 20px rgba(126, 127, 134, 0.2);
                z-index: 99999;

                a {
                  display: inline-block;
                  width: 200px;
                  margin: 0;
                  padding: 8px 15px;

                  &.active {
                    color: $primary-color;
                  }
                }

                &.deals-submenu {
                  left: 10px;
                  width: 140px;
                }

                &.language-submenu {
                  left: 0px;
                  width: 100px;
                }
              }
            }
          }

          .login-button {
            margin-right: 0;
            padding: 5px 10px;
            font-size: 18px;
          }
        }

        .nav-search-container {
          display: flex;
          align-items: flex-end;
          padding-bottom: 20px;

          .component-search-bar {
            justify-content: flex-end;
          }
        }
      }

      .mobile-nav-toggle-container {
        display: none;
      }
    }
  }

  .mobile-nav-links {
    display: none;
  }

  .default-content.login-popup-open {
    position: relative;
  }

  .default-footer {
    font-family: "League Spartan", sans-serif;
    background: $primary-blue;
    padding-bottom: 40px;

    .component-default-container {
      .footer-main-call-to-action {
        padding: 40px 0 30px 0;
        color: $light-blue;
        font-weight: 700;
        text-align: center;
        border-bottom: 1px solid $light-grey;
        font-size: 18px;

        .actions {
          display: inline-block;
        }

        a.learn-more-link {
          margin-left: 40px;
          margin-right: 10px;
          color: $light-blue;
          text-decoration: underline;
        }

        a.component-button.book-demo-button {
          margin-left: 10px;
          padding: 5px 10px;
        }
      }

      .footer-info-sections {
        margin-top: 20px;
        display: flex;
        color: $light-blue;
        position: relative;

        .footer-section {
          margin-right: 40px;

          h2 {
            font-size: 16px;
            font-weight: 800;
            color: $light-blue;
          }

          a {
            color: $light-blue;
            font-size: 16px;

            .social-icon {
              font-size: 20px;
            }
          }

          ul {
            list-style: none;
            padding-left: 0;
          }

          &.app-links-section {
            .app-links {
              margin-top: 10px;
              a {
                display: inline-block;
                margin-bottom: 10px;

                img {
                  height: 40px;
                }
              }
            }
          }

          &.links-section {
            line-height: 30px;
          }

          &.about-section {
            width: 426px;
            line-height: 22px;

            img.logo {
              width: 162px;
              margin-bottom: 20px;
            }

            .about-writeup {
              font-size: 16px;
            }

            .social-links {
              margin-top: 30px;

              a {
                margin-right: 10px;

                .social-icon {
                  font-size: 24px;
                }
              }
            }
          }

          &.login-section {
            flex-grow: 1;
            margin-right: 0;
            .login-button {
              float: right;
              white-space: nowrap;
              font-size: 18px;
              padding: 5px 10px;
            }
          }
        }
      }
    }

    $condensed-footer-width: 1050px;
    @media screen and (max-width: #{$condensed-footer-width}) {
      .component-default-container {
        .footer-main-call-to-action {
          .actions {
            display: block;
            margin: 10px 0;
          }
        }
      }
    }

    @media screen and (max-width: #{$mobile-device-width}) {
      .component-default-container {
        .footer-info-sections {
          display: block;
          .footer-section {
            margin: 10px 0 20px 0;
            &.about-section {
              width: 100%;
            }

            &.login-section {
              .login-button {
                float: left;
              }
            }
          }
        }
      }
    }
  }

  $condensed-menu-max-width: 1050px;
  $mobile-menu-max-width: 890px;

  @media screen and (max-width: #{$condensed-menu-max-width}) {
    .default-header {
      .component-default-container {
        .logo-container {
          width: 200px;

          img.logo {
            width: 170px;
          }
        }

        .nav-actions {
          .nav-links {
            a {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: #{$mobile-menu-max-width}) {
    $mobile-header-height: 80px;

    .default-header {
      .component-default-container {
        height: $mobile-header-height;

        .nav-actions {
          .nav-links {
            display: none;
          }

          .mobile-nav-toggle-container {
            display: block;

            .mobile-nav-toggle {
              font-size: 30px;
            }
          }

          .nav-search-container {
            display: none;
          }
        }
      }
    }

    .mobile-nav-links {
      display: block;
      position: absolute;
      top: $mobile-header-height;
      z-index: 1068;
      background: white;
      width: 100%;
      max-height: calc(100% - #{$mobile-header-height});
      overflow-y: scroll;

      a {
        cursor: pointer;
        display: block;
        padding: 20px 0;
        text-align: center;
        border-top: 1px solid $gray;

        &:hover {
          color: $primary-color-dark-1;
        }
      }
      .language-submenu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 40px;

        .mobile-lang-link {
          cursor: pointer;

          &:hover {
            color: $primary-color-dark-1;
          }

          &.active {
            text-decoration: underline;
            text-decoration-color: $primary-color;
            text-underline-offset: 0.2em;
          }
        }
      }
    }
  }
}
