@import "../../scss/base/colorswatches.scss";

div.component-dashboard-redemptions-table {
  margin: 60px auto 0 auto;
  .redemptions-title {
    margin-bottom: 10px;
  }
  .component-dashboard-filter {
    position: absolute;
    top: 20px;
    left: 0;
  }
  .view-all {
    margin: 30px 0;
    align-self: flex-end;
  }
  .search-bar {
    position: absolute;
    top: -250px;
    right: 0;
  }
  .search-bar.admin {
    top: -120px;
  }

  .component-dashboard-filter-display {
    position: absolute;
    top: -70px;
    right: 20px;
  }
  .no-redemptions-message {
    margin-bottom: 50px;
  }

  @media screen and (max-width: 768px) {
    .dashboard-redemptions-table-header {
      margin-top: 40px;
      .search-bar {
        position: relative;
        top: 0;
        left: 0;
      }
      .component-dashboard-filter-display {
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }
}
