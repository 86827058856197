@import "../../scss/base/colorswatches.scss";

.component-deal-type-overlay {
  position: relative;
  color: white;
  width: 330px;
  margin-bottom: 14px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 36px;
  }
  .deal-type-container {
    position: absolute;
    top: 5px;
    font-weight: bold;
    z-index: 2;
    color: white;
    display: flex;
    align-items: center;
    width: 100%;

    i {
      margin-right: 10px;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 900px) {
    img {
      height: 30px;
    }
    .deal-type-container {
      top: 1px;
      align-items: center;
      font-size: 13px;
      padding: 5px 10px 0 10px;
    }
  }
}
