@import "../../../scss/base/colorswatches.scss";

.component-similar-deals {
  .caption {
    margin-bottom: 62px;
  }

  .deals-list {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 22px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      border: 1px solid $secondary-blue;
    }

    &::-webkit-scrollbar-thumb {
      background: $secondary-blue;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .search-results ul.ais-Hits-list {
      display: flex !important;
      gap: 140px;
      padding: 0;

      list-style: none;

      .ais-Hits-item {
        padding: 0;
        margin: 0;
      }
    }
  }

  .algolia-powered-by-container {
    margin: 20px 0;
    text-align: center;
  }

  @media screen and (max-width: 900px) {
    .caption {
      margin-bottom: 43px;
    }
  }
}
