@import '../../../scss/base/colorswatches.scss';

.component-testimonial-slide {
  padding-bottom: 45px;

  .content {
    max-width: 873px;
    margin: 0 auto;
    text-align: center;

    .quote {
      line-height: 38px;
      font-size: 24px;
      color: $primary-blue;
    }

    .image {
      margin-top: 20px;
      img {
        height: 80px;
        width: 80px;
      }
    }

    .name {
      margin-top: 10px;
      font-size: 24px;
      color: $primary-blue;
    }

    .role {
      margin-top: 7px;
      font-size: 18px;
      color: $dark-grey;
    }
  }
}