// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";

@import "~@coreui/coreui/scss/coreui.scss";
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

@import "../../../scss/app";
@import "../../../scss/base/globals.scss";
@import "../../../scss/base/colorswatches.scss";

.component-portal-layout {
  background-color: $primary-white;

  .portal-app-body {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow-x: hidden;

    margin-top: 0;
    height: 100vh;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
    .main {
      overflow-y: auto;
      flex: 1 1;
      min-width: 0;
    }

    .mobile-navigation-container {
      display: none;
    }

    @media screen and (max-width: #{$mobile-device-width}) {
      display: flex;
      flex-direction: column;

      .mobile-navigation-container {
        .mobile-menu-toggle {
          display: inline-block;
          font-size: 32px;
          padding: 10px;
        }

        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .portal-unauthorized {
    padding: 50px 0;
    text-align: center;

    .logo-container {
      margin: 20px 0;
    }

    .main-message {
      font-size: 18px;
    }

    .actions {
      margin: 20px 0;
    }
  }
}
