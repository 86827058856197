@import "../../scss/base/colorswatches.scss";

.component-feed {
  h2 {
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 20px;
    font-weight: bold;
  }

  .feed-layout {
    display: flex;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;

    .feed-main-content {
      width: calc(100% - 350px);
      padding-right: 40px;

      .component-deals-list {
        .component-deal-listing {
          margin: 20px 0 30px 0;
        }
      }

      .empty-state-deals {
        text-align: center;
        margin: 30px 0;
        font-size: 20px;
        color: $gray;

        a {
          text-decoration: underline;
        }
      }
    }

    .feed-sidebar {
      width: 350px;
    }

    .compact-footer {
      border-top: 1px solid $light-grey;
      padding-top: 10px;

      a {
        margin-right: 12px;
      }

      .social-links {
        margin-top: 10px;
      }
    }

    @media screen and (max-width: 900px) {
      .feed-main-content {
        width: calc(100% - 250px);
      }

      .feed-sidebar {
        width: 250px;
      }
    }

    @media screen and (max-width: 800px) {
      .feed-main-content {
        width: 100%;
        padding-right: 0;
      }

      .feed-sidebar {
        display: none;
      }
    }
  }
}
