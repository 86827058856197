@import "../../scss/base/colorswatches.scss";

.component-deal-exclusive-banner {
  .exclusive-deal-banner {
    color: white;
    background-color: $primary-green;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 5px;
    max-width: 1340px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 34px;
    position: relative;
    padding: 0 10px;

    .exclusive-deal-banner-content {
      width: 98%;
      text-align: center;
    }

    i.close-banner-button {
      font-size: 30px;
      cursor: pointer;

      &:hover {
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }

    .deal-exclusive-banner-link {
      color: $primary-white;
      text-decoration: underline;
      text-underline-offset: 3px;
      &:hover {
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }

  .success {
    background-color: $primary-blue;
  }

  @media screen and (max-width: 900px) {
    .exclusive-deal-banner {
      height: fit-content;
      padding: 5px 0px;
      width: 95vw;
      position: relative;
      left: 52%;
      margin-left: -49vw;
      i.close-banner-button {
        font-size: 12px;
        padding-right: 5px;
      }
    }
  }
}
