@import '../../../scss/base/colorswatches.scss';

.component-banner {
  text-align: center;
  padding: 10px;
  border-radius: 3px;
  &.error {
    background: $error-color-light-3;
    color: $error-color;
  }
}